// import Destination from "../components/Destination";
// import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
// import Trip from "../components/Trip";
import heroi from "../assets/Solopacker1920.jpg"; // Correct import path
import Destination from "../components/Destination";
import Trip from "../components/Trip";
import Footer from "../components/Footer";
import UpcommingTrip from "../components/UpcommingTrip"; 
import Why from "../components/Why.js";
import NextTrip from "../components/NextTrip.js";
import TripGalary from "../components/TripGalary.js";
import Testimonials from "../components/Testimonials.js";


function Home() {
  return (
    <>
     <Navbar/> 
     <Hero
     cName="hero"
     heroImg={heroi} 
     title="Arrive solo,
      leave as friends"
     text="Create Memories Spread Happiness"
     buttontext="Travel Plan"
     url="/"
     btnclass="show"
     />
      <Why />
      <NextTrip />
{/* <Destination /> */}
{/* <Trip /> */}
<TripGalary />
<Testimonials />
{/* <UpcommingTrip /> */}
<Footer />

    </>
  );
}

export default Home;
