export const MenuItems = [
   {
    title : "Home",
    url :"/",
    cName : "nav-links",
    icon : "fa-solid fa-house-flag"
   },
   {
    title : "About",
    url :"/about",
    cName : "nav-links",
    icon : "fa-solid fa-circle-info"
   },
   {
    title : "Trips",
    url :"/service",
    cName : "nav-links",
   //  icon : "fa-solid fa-briefcase"
    icon : "<fa-sharp fa-solid fa-plane-departure"
   },
   {
    title : "Contact",
    url :"/contact",
    cName : "nav-links",
    icon : "fa-solid fa-address-book"
   },
   {
    title : "Sign Up",
    url :"/signup",
    cName : "nav-links-mobile",
     
   }
];
