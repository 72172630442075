import React, { useEffect } from "react";
import "./TripGalary.css"; // Make sure to include your styles
import Mountain1 from "../assets/G1-min.jpg" 
 import Mountain2 from "../assets/G2-min.jpg" 
 import Mountain3 from "../assets/G3-min.jpg" 
 import Trip1 from "../assets/G5-min.jpg"
 import Trip2 from "../assets/G4-min.jpg"
 import Trip3 from "../assets/G6-min.jpg"
  
const TripGalary = () => {
  useEffect(() => {
    let scrollContainer = document.querySelector(".gallery");
    let backBtn = document.getElementById("backBtn");
    let nextBtn = document.getElementById("nextBtn");

     
    // Horizontal scroll on mouse wheel event
    // scrollContainer.addEventListener("wheel", (evt) => {
    //   evt.preventDefault();
    //   scrollContainer.scrollLeft += evt.deltaY;
    //   scrollContainer.style.scrollBehavior = "auto";
    // });

    
    nextBtn.addEventListener("click", () => {
      scrollContainer.style.scrollBehavior = "smooth";
      scrollContainer.scrollLeft += 900;
    });

    // Scroll left when back button is clicked
    backBtn.addEventListener("click", () => {
      scrollContainer.style.scrollBehavior = "smooth";
      scrollContainer.scrollLeft -= 900;
    });

    // Clean up event listeners when component unmounts
    return () => {
      // scrollContainer.removeEventListener("wheel", () => {});
      nextBtn.removeEventListener("click", () => {});
      backBtn.removeEventListener("click", () => {});
    };
  }, []);

  return (
    <div>
    <h1 id="gh">Trip Gallery</h1>
    <p id="gp">A World to See, A Gallery to Explore</p>

    <div 
    className="gallery-wrap">
      {/* Back button with left chevron icon */}

      <button id="backBtn">
        <i className="fas fa-chevron-left"></i>
      </button>

      <div className="gallery-wrap">
        {/* Gallery section */}
        <div className="gallery">
          <div>
            <span>
              <img src={Mountain1}alt="nature1" />
            </span>
            <span>
              <img src={Mountain2} alt="nature2" />
            </span>
            
            <span>
              <img src={Mountain3}
              alt="nature2" />
            </span>
          </div>
          <div>
            <span>
              <img src={Trip1} alt="nature1" />
            </span>
            <span>
              <img src={Trip2} alt="nature2" />
            </span>
            <span>
              <img src={Trip3} alt="nature3" />
            </span>
          </div>
        </div>
      </div>

      {/* Next button with right chevron icon */}
      <button id="nextBtn">
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
    </div>
  );
};

export default TripGalary;
