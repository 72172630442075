import React from 'react';
import "./WhyStyle.css";

import W1 from "../assets/w1.jpeg";
import W2 from "../assets/Ab2.jpg";
import W3 from "../assets/G3-min.jpg";
import W4 from "../assets/G4-min.jpg";

function Why() {
  return (
    <div className="why">
      <h1>Why Choose Solopackers</h1>
      <p>Travel with like-minded people on unique adventures!</p>
      
      <div className="why-cards">
        <div className="why-card">
          <img src={W1} alt="W1 Image" />
        </div>
        <div className="why-card">
          <img src={W2} alt="W2 Image" />
        </div>
        <div className="why-card">
          <img src={W3} alt="W3 Image" />
        </div>
        <div className="why-card">
          <img src={W4} alt="W4 Image" />
        </div>
      </div>

      <div className="why-text">
        <div className="text-card">
          <i className="fas fa-users icon"></i>
          <h3>Like-minded People</h3>
          <p>We cater to solo travelers, focusing on group dynamics and unique experiences.</p>
        </div>
        <div className="text-card">
          <i className="fas fa-map-marked-alt icon"></i>
          <h3>Unreplicable Trips</h3>
          <p>Our adventures consist of curated experiences and handpicked hotels.</p>
        </div>
        <div className="text-card">
          <i className="fas fa-umbrella-beach icon"></i>
          <h3>Hassle-Free</h3>
          <p>We take care of all the details – you just need to show up and enjoy!</p>
        </div>

        <div className="text-card">
          <i className="fas fa-shield-alt icon"></i>
          <h3>Value Guarantee</h3>
          <p>Custom trips aren't pricey! Inquire about our price assurance and book with confidence.</p>
        </div>
      </div>
    </div>
  );
}

export default Why;
