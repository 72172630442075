import { Link } from 'react-router-dom';
import AboutUs from "./AboutUs";
import About from "../routes/About";
import ContactUs from './ContactForm';
import "./FooterStyles.css";
const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

    return (
       
        <footer>
          <div className="row">
            <div className="col">
              
              <h1 className="logos">Solopackers</h1>
              <p id="pff1">"Journey To Untouched, Offbeat Destinations! Discover Hidden Gems And Unforgettable Experiences, Crafted For The Curious Explorer In You. 🌍✈️ #GoOffbeat🌄 #Solopackers!"</p>
            </div>
            <div className="col">
              <h3>Office <div className="underline"><span /></div></h3>
              <p id="pf">B/1004</p>
              <p id="pf"> Opp Oberoi Mall</p>
              <p id="pf">Malad East, Mumbai, 400097, India</p>
              <p className="email-id">info@solopackers.com</p>
              <h4>+91 - 9987625210</h4>
            </div>
            {/* <div className="col">
              <h3>Links <div className="underline"><span /></div></h3>
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="/About">About Us</a></li>
                <li><a href="/service">Trips</a></li>
                <li><a href="#">OurTeam</a></li>
                <li><a href="#">Career</a></li>
                <li><a href="/contact">Contacts</a></li>
              </ul>
            </div> */}

<div className="col">
          <h3>Links <div className="underline"><span /></div></h3>
          <ul>
            <li><Link to="/" onClick={scrollToTop} >Home</Link></li>
            <li><Link to="/about" onClick={scrollToTop}>About Us</Link></li>
            <li><Link to="/service" onClick={scrollToTop}>Trips</Link></li>
            <li><Link to="#" onClick={scrollToTop}>Our Team</Link></li>
            <li><Link to="#" onClick={scrollToTop}>Career</Link></li>
            <li><Link to="/contact" onClick={scrollToTop}>Contacts</Link></li>
          </ul>
        </div>

            <div className="col">
              <h3>Newsletter <div className="underline"><span /></div></h3>
              <form>
                <i className="far fa-envelope" />
                <input type="email" placeholder="Enter your email id" required />
                <button type="submit"><i className="fas fa-arrow-right" /></button>
              </form>
              {/* <div className="social-icons">
                <i className="fab fa-facebook-f" />
                <i className="fab fa-twitter" />
                <i className="fab fa-instagram" />
                <i className="fab fa-whatsapp" />
                <i className="fab fa-linkedin" />
              </div> */}

<div className="social-icons">
            <a
              href="https://www.facebook.com/solopackers"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
            {/* <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter" />
            </a> */}
            <a
              href="https://www.instagram.com/solopackers?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              href="https://wa.me/91998762521"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp" />
            </a>
            <a
              href="https://www.linkedin.com/company/solopackers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin" />
            </a>
          </div>



            </div>
          </div>
          <hr />
          <p id="pff" className="copyright">Solopackers © 2024 - All Rights Reserved</p>
        </footer>
      // </div>
    );
  };
  export default Footer;