import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
 
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
// import abouti from "../assets/About1.jpg";
import abouti from "../assets/Ab.jpg";
import Destination from "../components/Destination";
function About() {
  return (
    <>
     <Navbar/> 
     <Hero
     cName="hero-mid"
     heroImg={abouti} 
     title="About Us"
      
      
     btnclass="hide"
     />
     <AboutUs/>
     <Footer />
    </>
  );
}

export default About;
