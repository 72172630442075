import "./TripStyles.css";
import TripData from "./TripData";
import Trip1 from "../assets/5.jpg"
import Trip2 from "../assets/8.jpg"
import Trip3 from "../assets/6.jpg"

function UpcommingTrip() {
  return (
    <div className="trip">
      <h1>Our Upcomming Trips</h1>
      <p>Explore The Inner Explorer In You</p>
      <div className="tripcard">
        <TripData
        images={Trip1}
        heading="Trip in Indonesia"
        text="something that is filled with authentic and immersive Indian experiences. Of course we also do visit the must-see tourist attractions – after all, it would be unfair if these beautiful spots are ignored. We promise with us you will travel India the way it ought to be travelled. Do go through our website and have a look at some of our tour 
         "
        />

<TripData
        images={Trip2}
        heading="Trip in Singapur"
        text="something that is filled with authentic and immersive Indian experiences. Of course we also do visit the must-see tourist attractions – after all, it would be unfair if these beautiful spots are ignored. We promise with us you will travel India the way it ought to be travelled. Do go through our website and have a look at some of our tours "
        />

<TripData
        images={Trip3}
        heading="Trip in Bali"
        text="something that is filled with authentic and immersive Indian experiences. Of course we also do visit the must-see tourist attractions – after all, it would be unfair if these beautiful spots are ignored. We promise with us you will travel India the way it ought to be travelled. Do go through our website and have a look at some of our tours "
        />

      </div>
    </div>
  );
}

export default UpcommingTrip;
