import { Component } from "react";
import "./NavbarStyles.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";
import logo from "../assets/solologo.png";

class Navbar extends Component {
  state = { clicked:false };
  handleclick = () => {
    this.setState({clicked: !this.state.clicked})
  }
  render() {
    return (
      <nav className="NavbarItems">
        <div className="logo-brand">
          <img src={logo} alt="Solo Packers Logo" className="navbar-logo-img" />
          <h1 className="navbar-brand">Solopackers</h1>
        </div>

        <div className="menu-icons" onClick={this.handleclick}>  
          
          <i className={this.state.clicked ? "fas fa-times": "fas fa-bars"}></i>
         </div>

        <ul className={this.state.clicked ?"nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return(

<li key={index}>
            <Link className={item.cName} to={item.url}>
              <i className={item.icon}></i>{item.title}
            </Link>
          </li>

            );
          })}

          {/* <button>Sign Up</button> */}
          
          {/* Add other menu items here */}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
