// Testimonials.jsx
import React,  { useEffect } from 'react';
import './Testimonials.css'; // Import the CSS file

const Testimonials = () => {

  return (
    <div className="containert">
      <div className="headert">
        <h1>Hear From Travellers Like You</h1>
        {/* <hr /> */}
      </div>
      <div className="testimonialst">
        <button className="backBtnt">
          <i className="fas fa-chevron-left"></i>
        </button>

        <div className="testimonial-cardt">
          <img
            src="https://storage.googleapis.com/a1aa/image/iC4vN5gELsZ2MV6XBeE1jmfv4zI8TwfeoAR4429EHySLBBYOB.jpg"
            alt="Profile picture of Ashmiha Anwar"
            width="80"
            height="80"
          />
          <h3>Ashmiha Anwar</h3>
          {/* <p>15 March 2024</p> */}
          {/* <div className="quotet">“</div> */}
          <div className="contentt">
          Solopackers is a community that tries to connect backpackers to have an amazing experience on the trip. I had a great time in their Pondicherry backpacking.
          </div>
          {/* <a href="#" className="read-moret">
            Continue Reading
          </a> */}
        </div>

        <div className="testimonial-cardt">
          <img
            src="https://storage.googleapis.com/a1aa/image/NeTGfeBxNYUN5ISEfbrIb0cUAEbHqPKvsMpfePC1WQ1BFEg5E.jpg"
            alt="Profile picture of Smriti Jain"
            width="80"
            height="80"
          />
          <h3>Smriti Jain</h3>
          {/* <p>29 February 2024</p> */}
          {/* <div className="quotet">“</div> */}
          <div className="contentt">
            I recently had the pleasure of going on a trip with Solopackers, I'll never forget. The people I traveled with were fantastic company, and the captain was Super Cool.
          </div>
          {/* <a href="#" className="read-moret">
            Continue Reading
          </a> */}
        </div>

        <div className="testimonial-cardt">
          <img
            src="https://storage.googleapis.com/a1aa/image/RzzXxbLyhTqfJC4uMK0iDMr1l53T2Zy2WIJYxXvgMBQLIAzJA.jpg"
            alt="Profile picture of Arya Ravindran"
            width="80"
            height="80"
          />
          <h3>Arya Ravindran</h3>
          {/* <p>15 March 2024</p> */}
          {/* <div className="quotet">“</div> */}
          <div className="contentt">
            This was my first community trip and the experience was amazing. I just wanted to take a moment to personally thank Solopackers for organizing such an amazing Trip.
          </div>
          {/* <a href="#" className="read-moret">
            Continue Reading
          </a> */}
        </div>

        <div className="testimonial-cardt">
          <img
            src="https://storage.googleapis.com/a1aa/image/O6tZ2nHGR1rsNBUX80fmvNibQLB4jU7XwOOSm3ZYSViLIAzJA.jpg"
            alt="Profile picture of Aayush Saraf"
            width="80"
            height="80"
          />
          <h3>Aayush Saraf</h3>
          {/* <p>26 August 2024</p> */}
          {/* <div className="quotet">“</div> */}
          <div className="contentt">
            I went on a really cool 3-day trip to Pondicherry with Solopackers, and it was awesome! Everything about it was exciting and fun. The place we stayed at was super nice.
          </div>
          {/* <a href="#" className="read-moret">
            Continue Reading
          </a> */}
        </div>

        <button className="nextBtnt">
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
