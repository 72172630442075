import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
 
import Footer from "../components/Footer";
import Trip from "../components/Trip";
import Servicei from "../assets/Servicei.avif";
import UpcommingTrip from "../components/UpcommingTrip";
import NextTrip from "../components/NextTrip";
function Service() {
  return (
    <>
     <Navbar/> 
    
     <Hero
     cName="hero-mid"
     heroImg={Servicei} 
     title="Trips"
      
      
     btnclass="hide"
     />
   {/* <UpcommingTrip /> */}
<NextTrip/>
     <Footer />
     
     
    </>
  );
}

export default Service;
