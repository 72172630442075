import "./NextTrip.css";
import React from 'react'
import N11 from "../assets/K2a.png";
import N2 from "../assets/Ker1-min.png";
import N3 from "../assets/k1.jpg";
// import A1 from "./At1.pdf";


const NextTrip = () => {

  const handleCardClick = () => {
    window.open(
      'https://drive.google.com/file/d/1-KcvP4Vbr_nQQ6BcjKqwM71RZRQcF9zT/view?usp=drivesdk',
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <div className="container">
    <div className="header">Our Upcoming Trips
      <p>Let's Take a Lifelong Memory</p>
    </div>
    <div className="trips"  onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <div className="trip-card">
        <img
          alt="Kerala Escapade (Kochi-Alleppey-Varkala)"
         
          src={N11}
           
        />
        <div className="trip-info">
          <h3>Explore Kerala </h3>
          <p>
            <i className="fas fa-moon icon"></i> 4 Nights 5 Days
            <br />
            <i className="fas fa-map-marker-alt icon"></i> Kerala, India
            <br />
            <i className="fas fa-calendar-alt icon"></i> October 26, 2024
          </p>
          <div className="price">₹ 14,999 </div>
          <a className="details" href='https://drive.google.com/file/d/1-KcvP4Vbr_nQQ6BcjKqwM71RZRQcF9zT/view?usp=drivesdk' target="_blank" >
            VIEW DETAILS
          </a>

          
        </div>
      </div>
  
      <div className="trip-card">
        <img
          alt="Wayanad Backpacking trip- (2 Nights/ 3 Days) Long Weekend"
          src={N2}
           
        />
        <div className="trip-info">
          <h3>Explore Kerala </h3>
          <p>
            <i className="fas fa-moon icon"></i> 4 Nights 5 Days
            <br />
            <i className="fas fa-map-marker-alt icon"></i> Kochi, Kerala
            <br />
            <i className="fas fa-calendar-alt icon"></i> November 02, 2024
          </p>
          <div className="price">₹ 14,999</div>
          <a className="details" href='https://drive.google.com/file/d/1-KcvP4Vbr_nQQ6BcjKqwM71RZRQcF9zT/view?usp=drivesdk' target="_blank" 
           onClick={(e) => e.stopPropagation()}
          >
            VIEW DETAILS
          </a>
        </div>
      </div>
  
      <div className="trip-card">
        <img
          alt="Kodaikanal Backpacking trip - 2 nights & 3 days"
          src={N3}
        />
        <div className="trip-info">
          <h3>Explore Kerala </h3>
          <p>
            <i className="fas fa-moon icon"></i> 4 Nights 5 Days
            <br /> 
            <i className="fas fa-map-marker-alt icon"></i> Kochi, Kerala
            <br />
            <i className="fas fa-calendar-alt icon"></i> November 09, 2024
          </p>
          <div className="price">₹ 14,999</div>
          <a className="details" href="https://drive.google.com/file/d/1wj7z0f7T8D1MIjVvm8jqPBZ2ddAwkP8S/view?usp=drive_link" target="_blank" >
            VIEW DETAILS
          </a>
        </div>
      </div>

    </div>
  </div>
  
    
  )
}

export default NextTrip