import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
 
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import Contacti from "../assets/contacti.jpg";



function Contact() {
  return (
    <>
     <Navbar/> 
     <Hero
     cName="hero-mid"
     heroImg={Contacti} 
     title="Contact Us"
      
      
     btnclass="hide"
     />
     <ContactForm/>
      <Footer />
     
    </>
  );
}

export default Contact;
