import "./AboutUsStyles.css";

function AboutUs() {
  return (
    <div className="about-container">
      <h1 className="ht">AboutUs</h1>
      <p id="pfa">
      At SoloPaker, we are more than just a travel company; we are a vibrant community of travelers who believe in exploring the world with like-minded adventurers. We offer end-to-end travel packages across India and beyond, meticulously designed to create unforgettable journeys. Whether you're looking for a road trip, a trekking expedition, corporate getaways, or a customized tour package, SoloPaker has got you covered.

Our travel itineraries are crafted to provide hassle-free experiences, with top-notch accommodations, guided sightseeing, and a seamless travel experience – all within a budget that won’t burn a hole in your pocket. So, give us your dates, pack your bags, and get ready to explore the world with SoloPaker like never before!
         </p>

         <h1 className="ht">Our Vision</h1>
      <p id="pfa">
      At SoloPaker, we envision a world where travel is not just a journey but a transformative experience that connects people, cultures, and stories. We aim to become the leading travel community that inspires and empowers individuals to explore the world with confidence, fostering a sense of belonging among like-minded travelers.

 
         </p>

         <h1 className="ht">Our Mission</h1>
      <p  id="pfa">
      Our mission is to redefine the travel experience by offering unique, curated trips that cater to the needs and desires of modern adventurers. We are committed to providing exceptional services, from hassle-free planning and expert-guided tours to comfortable accommodations and memorable experiences. At SoloPaker, we strive to make travel accessible, affordable, and unforgettable for everyone.
      
         </p>

    </div>
  );
}

export default AboutUs;
