// import "./ContactFormStyles.css";

// function ContactForm() {
//   return (
//     <div className="from-container">
//       <h1>Send a message to us!</h1>
//       <form>
//         <input placeholder="Name"/>
//         <input placeholder="Email"/>
//         <input placeholder="Mobile No"/>
//         <input placeholder="Subject"/>
//         <textarea placeholder="Message" rows="4"></textarea>
//         <button>Send Message</button>
//       </form>
//     </div>
//   );
// }

// export default ContactForm;



import "./ContactFormStyles.css";

function ContactForm() {
  return (
    <div className="form-container">
      <h1>Get in Touch with Us!</h1>
      <form>
        <input type="text" placeholder="Your Name" />
        <input type="email" placeholder="Your Email" />
        <input type="tel" placeholder="Your Mobile No" />
        <input type="text" placeholder="Subject" />
        <textarea placeholder="Write your message..." rows="5"></textarea>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
}

export default ContactForm;
